import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const View = ({props}) => {
    return (
        <OverlayTrigger
        key={'top'}
        placement='top'
        overlay={
        <Tooltip id={`tooltip-top`}>
            Click to view
        </Tooltip>
        }>
        <Link to="/app/used-cars/view" state={{ carData: props.data.actualData }} >
        <i class="far fa-eye"  style={{color: 'gray'}}/>
        </Link>
        </OverlayTrigger>
    );
}

export default View;
