
const initialState={
    gridRef: null,
    gridApi: null
}

export const TableReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'SET_GRID_REF':
            return{
                ...state,
                gridRef: action.payload
            }
        case 'SET_GRID_API':
            return{
                ...state,
                gridApi: action.payload
            }
        default:
            return state;
    }
}