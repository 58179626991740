import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Edit = ({props}) => {
    return (
        <OverlayTrigger
        key={'top'}
        placement='top'
        overlay={
        <Tooltip id={`tooltip-top`}>
            Click to edit
        </Tooltip>
        }>
        <Link to="/app/used-cars/edit" state={{carData: props.data.actualData}} >
        <i class="fas fa-edit" style={{color: 'gray'}}/>
        </Link>
        </OverlayTrigger>
    );
}

export default Edit;
