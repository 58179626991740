import { tableActionTypes } from "./table.types";

export const fetchTableStart = () => ({
    type: tableActionTypes.FETCH_TABLE_START
})

export const fetchTableSuccess = (data) => ({
    type: tableActionTypes.FETCH_TABLE_SUCCESS,
    payload: data
})

export const fetchTableFailure = (error) => ({
    type: tableActionTypes.FETCH_TABLE_FAILURE,
    payload: error
});
// actions.js or any relevant file
export const setGridRef = (gridRef) => {
    return {
      type: 'SET_GRID_REF',
      payload: gridRef
    };
  };
  