import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavItem from './NavItem';
import { FiHome } from "react-icons/fi";
import { AiOutlineUser } from 'react-icons/ai';
import { ImUserPlus } from 'react-icons/im';
import { AiFillCar } from 'react-icons/ai';
import { MdOutlineMiscellaneousServices, MdSupportAgent } from 'react-icons/md';
import { BsChatLeftDots, BsFillExclamationDiamondFill } from 'react-icons/bs';
import {SiDatabricks } from 'react-icons/si';
import {AiOutlineMobile} from 'react-icons/ai';
import {FaDollarSign, FaUserShield} from 'react-icons/fa';
import { BiListOl } from "react-icons/bi";
import '../dashboardlayout.module.scss'
import { GiReceiveMoney, GiSpanner } from "react-icons/gi";
import { FaCarTunnel } from "react-icons/fa6"


const items = [
  {
    id:1,
    href: '/app/dashboard',
    icon: < FiHome />,
    title: 'Dashboard'
  },
  {
    id:2,
    href: '/app/users',
    icon: < AiOutlineUser />,
    title: 'Mobile Users'
  },
  // {
  //   id:3,
  //   href: '/crm/users',
  //   icon: < ImUserPlus />,
  //   title: 'CRM Users'
  // },
  {
    id:4,
    href: '/app/vehicleType',
    icon: < AiFillCar />,
    title: 'Vehicle Type'
  },
  // {
  //   id:5,
  //   href:'/app/used-cars',
  //   icon: <FaCarTunnel/>,
  //   title :'Used Cars'
  // },
  // {
  //   id:5,
  //   href: '/app/support',
  //   icon: < MdSupportAgent />,
  //   title: 'Support'
  // },
  {
    id:6,
    href: '/app/b2c-support',
    // icon: <FcSupport  />,
    icon: <GiSpanner />,
    // icon : <MdOutlineSupportAgent />,
    title: 'B2C-Support'
  },
  // {
  //   id:7,
  //   href: '/app/suggestions',
  //   icon: < BsChatLeftDots />,
  //   title: 'Suggestions'
  // },
  {
    id:8,
    href: '/app/exportData',
    icon: < SiDatabricks />,
    title: 'Export Data'
  },
  {
    id:9,
    href: '/app/contentManagement',
    icon: < AiOutlineMobile />,
    title: 'App Content Management'
  },
  // {
  //   id:10,
  //   href: '/app/servicePlans',
  //   // icon: < img src={ServicePlanImage} />,
  //   icon : <MdOutlineMiscellaneousServices />,
  //   // style={{ filter: 'grayscale(100%)' }} 
  //   title: 'Service Plans'
  // },
  {
    id:11,
    href: '/app/b2bpayments',
    // icon: < img src={PaymentsImage} />,
    icon :<FaDollarSign />,
    // style={{ filter: 'grayscale(100%)' }}
    title: 'B2B Payments'
  },
  {
    id:12,
    href: '/app/b2cpayments',
    icon: <GiReceiveMoney />,
    title: 'B2C Payments'
  },
  {
    id:13,
    href: '/app/usersSubscriptions',
    icon: < FaUserShield />,
    title: 'Users Subscriptions'
  },
  {
    id:14,
    href: '/app/allBookings',
    icon: <BiListOl />,
    title: 'All Bookings'
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <div>
      <NavItem
        onMobileClose={onMobileClose}
        openMobile={openMobile}
        items={items}
      />
    </div>
  );

  return (
    <>

      {content}

    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
