import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import userMenuReducer from './userMenu/userMenu.reducer';
import usersSubscriptionCountObjReducer from './userSubscription/userSubscription.reducer'
import { TableReducer } from './Table/table.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'userMenu', 'usersSubscription','table']
};

const rootReducer = combineReducers({
  user: userReducer,
  userMenu: userMenuReducer,
  usersSubscription: usersSubscriptionCountObjReducer,
  table: TableReducer
});

export default persistReducer(persistConfig, rootReducer);
