import React, { useEffect, useState } from 'react';
import B2BPayments from './payment'
import { Link, Outlet, useNavigate } from 'react-router-dom';

const B2bPaymentInfo = () => {
    const navigate=useNavigate();
    const [activeTab, setActiveTab] = useState(0); 
    useEffect(()=>{navigate('/app/b2bpayments/payment-details')},[]);
    return (
        <div className="row m-0" >
        <div className="col-12">
          <div className="d-flex" >
            <h5 className="customTitleName">B2B payments details</h5>
          </div>
          <div className="card customCard">
            <div className="card-body" style={{height:'95vh'}}>
              <ul className="nav nav-tabs cunstomNavs">
                <li className={`${activeTab === 0 ? 'active' : ''}`}>
                  <Link to='/app/b2bpayments/payment-details' onClick={() => setActiveTab(0)}>Payments details</Link>
                </li>
                <li className={`${activeTab === 1 ? 'active' : ''}`}>
                  <Link to='/app/b2bpayments/successful-payments' onClick={() => setActiveTab(1)}>Successful Payments</Link>
                </li>
                <li className={`${activeTab === 2 ? 'active' : ''}`}>
                  <Link to='/app/b2bpayments/failed-payments' onClick={() => setActiveTab(2)}>Failed Payments</Link>
                </li>
              </ul>

              <div className="tab-content" >
                <div className="tab-pane active" >
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default B2bPaymentInfo;
