import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';
import styles from './dashboardlayout.module.scss'



const DashboardLayout = ({ isLoggedIn }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  if (!isLoggedIn) {
    return (<Navigate to="/login" />);
  }
  return (
    <div className='layout'>
      <div className="row">
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </div>
      <div className={`row mx-0 ${styles.marginTop}`}>
        <div className={` ${!isMobileNavOpen ? 'col-1' : styles.openMobileTrue}`}>
          <NavBar
            onMobileClose={setMobileNavOpen}
            openMobile={isMobileNavOpen}
          />
        </div>
        <div className={` ${!isMobileNavOpen ? 'col-11' : 'col-9'}`}>
           <Outlet />
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  isLoggedIn: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn
})

export default connect(mapStateToProps, null)(DashboardLayout);
