import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { API_APP_B2B_SUCCESS_PAYMENTS } from '../../utils/consts/api';
import SkeletonLoader from '../../components/Loader';
import AgTable from '../../components/Table/AgTable';
import { B2B_success_Payments_Fields } from '../../components/Table/tableData';

const SuccessPayments = ({token}) => {
    const [spinner,setSpinner]=useState(false);
    const [successPayments,setSuccessPayments]=useState([]);    

    const getSuccessfulPayments= async ()=>{
        setSpinner(true);
       try{
        const headers = {
            "Content-Type": "application/json",
            authorization: token,
          };
        let res= await axios.get(API_APP_B2B_SUCCESS_PAYMENTS , {headers} );
        if(res.data.responseCode === 0){
            setSuccessPayments(res.data.response);
            setSpinner(false);
        }else{
            toast.error(res.data.errorMsg, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSpinner(false);
        }
       }catch(err){
        setSpinner(false);
        toast.error("Internal server error.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }
    }

    useEffect(()=>{getSuccessfulPayments();},[successPayments.length]);

    return (
    <div style={{height:'95vh'}}>
    {
    spinner?
    <SkeletonLoader/> :
    <AgTable
        rowData={successPayments||[]}
        colDefs={B2B_success_Payments_Fields}
        section={"B2B_success_Payments"}
    />
      }
      </div>
    );
}

SuccessPayments.propTypes = {
    token: PropTypes.string,
  };
  
  const mapStateToProps = (state, props) => ({
    token: state.user.token,
  });
  
  export default connect(mapStateToProps, {})(SuccessPayments);
