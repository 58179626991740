import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Delete = ({props,token}) => {    
    return (
        <OverlayTrigger
        key={'top'}
        placement='top'
        overlay={
        <Tooltip id={`tooltip-top`}>
            Click to delete
        </Tooltip>
        }>
        <i class="far fa-trash-alt"  style={{color: 'gray', cursor: 'pointer'}} onClick={()=>props.context.handleDeleteData(props.data.actualData.userCarListingId)}/>
        </OverlayTrigger>
    );
}
// props.context.handleDeleteAccept(props.data.actualData.userCarListingId)
Delete.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(Delete);

