import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './customDropdown.module.scss'
const CustomDropdown = ({value,data, tableController}) => {
    const [selected,setSelected]=useState(null)

    useEffect(()=>{
        tableController(selected)
    },[selected])
    return (
    <Dropdown>
        <Dropdown.Toggle className={`${styles.searchButton}`}  id="dropdown-basic">
            {selected?selected:value}
        </Dropdown.Toggle>

        <Dropdown.Menu>
        {
            data.map((ele)=>{
                return (<>
                        <Dropdown.Item href="#/action-1" onClick={()=>setSelected(ele)}>{ele}</Dropdown.Item>
                        </>)
            })
        }
        </Dropdown.Menu>
    </Dropdown>
    );
}

CustomDropdown.propTypes={
    value:PropTypes.string,
    data:[]
}

export default CustomDropdown;
