import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import "./imageViewer.scss";

function CarousalViewer({ carImages, setCarImages , type }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [processedImages, setProcessedImages] = useState([]);

  useEffect(() => {
    // Convert File objects to URLs
    const newImages = carImages.map((img) =>
      img instanceof File ? URL.createObjectURL(img) : img
    );

    setProcessedImages(newImages);

    // Cleanup generated object URLs
    return () => {
      newImages.forEach((img) => {
        if (img.startsWith("blob:")) {
          URL.revokeObjectURL(img);
        }
      });
    };
  }, [carImages]); // Only re-run when carImages changes

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };



  return (
    <div className="carousel-main-container">
      <div className="image-container">
        {processedImages.map((src, index) => (
          <div
            key={index}
            onClick={() => openImageViewer(index)}
            className="image-wrapper"
            style={{ position: "relative" }}
          >
            <img
              src={src}
              width="100%"
              alt={`view ${index + 1}`}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            />
            <button
            className="delete-button"
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#cc0000")} // Darker red on hover
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff4d4d")} 
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                // Filter carImages instead of processedImages
                let updated = carImages.filter((_, i) => i !== index);
                setCarImages(updated ,type); // Update state in the parent
              }}
            >
              X
            </button>
          </div>
        ))}
      </div>

      {isViewerOpen && processedImages.length > 0 && (
        <div className="image-viewer">
          <ImageViewer
            src={processedImages}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.33)",
              backdropFilter: "blur(10px)",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
            closeOnClickOutside={false}
          />
        </div>
      )}
    </div>
  );
}

export default CarousalViewer;
