import React from 'react';
import Edit from './edit';
import View from './view';
import Delete from './delete';

const TableActions = (props) => {
    return (
        <div style={{display:"flex",gap:'20px',justifyContent:'center',alignItems:'center'}}>
            <View props={props}/>
            <Edit props={props}/>
            <Delete props={props}/>
        </div>
    );
}

export default TableActions;