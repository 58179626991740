export const validateFields = (data) => {
  for (const key in data) {
    if (key === "oldImages" || key === "carImages" || key=== 'isInspectionDone' || key==='isValueSatisfied') {
      continue; // Skip validation for these fields
    }

  

    if (data[key] === "" || (Array.isArray(data[key]) && data[key].length === 0)) {
      return `Please fill out the field: ${key}`;
    }
  }
  return true;
};
