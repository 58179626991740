import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import styles from './usedCars.module.scss'
import AgTable from '../../components/Table/AgTable';
import { UsedCarSalesFields } from '../../components/Table/tableData';
import axios from 'axios';
import { toast } from 'react-toastify';
import SkeletonLoader from '../../components/Loader';
import { API_ALL_USED_CARS, API_DELETE_USED_CARS } from '../../utils/consts/api';
import TableController from './Table_actions/TableController';

const UsedVehicleSales = ({token}) => {
        const store=useSelector((state)=>state);
        const [usedCarsListings,setUsedCarsListings]=useState([]);
        const [spinner,setSpinner]=useState(false);

        const handleDeleteData = async (id) => {  
          const requestData = {
              userCarListingId: `${id}`
          };
          
          const headers = {
              Authorization: token,
              "Content-Type": "application/json"
          };
          
          try {
              let response = await axios.delete(API_DELETE_USED_CARS, {
                  headers,
                  data: requestData 
              });
      
              if (response.status === 200) {
                  toast.success('Data deleted successfully', {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                  let newUSedCarListings=usedCarsListings.filter((data)=>{
                    return data.userCarListingId !== id
                  })
                  setUsedCarsListings(newUSedCarListings);
                  return response.data;
              } else {
                  
                  toast.error('Failed to delete car listing. Please try again.', {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
          } catch (err) {
              
              toast.error('An error occurred while deleting. Please try again later.', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });
          }
      };

        const getAllUsedCarListings = async (token) => {
          setSpinner(true);
          try {
            const response = await axios.get(API_ALL_USED_CARS, {
              headers: {
                Authorization: token, // Pass the token for authentication
              },
            });
            setUsedCarsListings(response.data.response.usedCarListings);
            setSpinner(false);
            return response.data;
          } catch (error) {
            setSpinner(false);
            
            throw error;
          }
        };

        useEffect(()=>{
          getAllUsedCarListings(token)
        },[]);

    return (
        <div>
            <div>
                <h1 className='customTitleName'>Used Car Sales</h1>
            </div>
            <TableController />
            <div className={styles.tableContainer} >
            {spinner?
            <SkeletonLoader/> :
            <AgTable
            rowData={usedCarsListings}
            colDefs={UsedCarSalesFields}
            section={'UsedCarSales'}
            handleDeleteData={handleDeleteData}
          />
            }
            </div>
        </div>
    );
}
UsedVehicleSales.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(UsedVehicleSales);