import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './usedCars.module.scss'
import { Accordion, Form } from 'react-bootstrap';
import { useImageCompression } from '../../components/ImageCompression/useImageCompression';
import { FaRegEdit } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import CarousalViewer from '../../components/Carousal/CarousalViewer';

const ViewUsedVehicle = ({token}) => {
    const {data,imageCompressionfn} = useImageCompression();
    const location=useLocation();
    const carData=location.state?.carData;
    

    return (
        <>
        <div className={styles.panelControl}>
          <h1 >View Vehicle</h1>
            <div className={styles.panelSub}>
            <Link to='/app/used-cars/sales' className={styles.cancelButton}>Cancel</Link>
            <Link to="/app/used-cars/edit" state={{carData}} className={styles.addVehicleButton} >
            <FaRegEdit size={20}/>Edit Vehicle
            </Link>
            </div>
        </div>

        <div className={styles.contentWrapper}>
    <Accordion defaultActiveKey='0'>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.accordianHeader} style={{height:'54px'}}>Car Details</Accordion.Header >
        <Accordion.Body>
        <form className={styles.formControl}>
        <div className={styles.formGroup} style={{height:'180px'}}>
            <label htmlFor="carImage">Car Images</label>
            <div className={styles.carouselContainer}>
              <CarousalViewer carImages={carData.carImages}/>
            </div>
        </div>


        <div className={styles.formGroup}>
            <label htmlFor="vehicleName">Vehicle Name</label>
            <span>{carData.vehicleName}</span>
        </div>

        <div className={styles.formGroup}>
            <label htmlFor="fuelType">Fuel Type</label>
            <span>{carData.fuelType}</span>
        </div>

        <div className={styles.formGroup}>
            <label htmlFor="transmission">Transmission</label>
            <span>{carData.transmission}</span>
        </div>

        <div className={styles.formGroup}>
            <label htmlFor="transmission">Location</label>
            <span>{carData.location}</span>
        </div>

        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
    <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Price for listing</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
        <div className={styles.formGroup}>
            <label htmlFor="price">Car Price</label>
            <span>{carData.carPrice}</span>
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="loan">Loan Remaining</label>
            <span>{carData.loanRemaining}</span>
        </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Certifications</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
          <div className={styles.formCheckBox}> 
            <div  >
            <input className={styles.checkBox} type="checkbox" id="satisfied" name="certifications" checked={carData.isValueSatisfied} />
            <label htmlFor="satisfied">Value satisfied</label>
            </div>
            <div >
            <input type="checkbox" id="inspection" name="certifications" checked={carData.isInspectionDone} />
            <label htmlFor="inspection">Inspection done</label>
            </div>
          </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Car overview details</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
            <div className={styles.formGroup}>
            <label htmlFor="ownership">Ownership</label>
            <span>{carData.ownershipYear}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="vehicleNumber">Vehicle Number</label>
            <span>{carData.vehicleNumber}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="vehicle Model">vehicle Model Number</label>
            <span>{carData.vehicleModelNumber}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="statePermit">All India Permit</label>
            <span>{carData.allIndiaPermit}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="All-india-Permit">All India Permit</label>
            <span>{carData.allIndiaPermit}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceType">Insurance Type</label>
            <span>{carData.insuranceType}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceValue">Insurance Value</label>
            <span>{carData.insuranceValue}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="fitnessExpiry">Fitness Expiry</label>
            <span>{carData.fitness}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="tyreCondition">Tyre Condition</label>
            <span>{carData.tyreCondition}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="authorization">Authorization</label>
            <span>{carData.authorization}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceExpiry">Insurance Expiry</label>
            <span>{carData.insuranceExpiry}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="totalKms">Total KMs Driven</label>
            <span>{carData.totalKmDriven}</span>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="stateTax">State Tax</label>
            <span>{carData.stateTax}</span>
            </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Report</Accordion.Header>
        <Accordion.Body className={styles.accordianBody}>
        <form className={styles.formControl}>
            <div className={styles.formGroup}>
            <label htmlFor="satisfied">Report</label>
            <div className={styles.report}>{carData.reportDescription}</div>
            </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
        </div>
        </>
    );
}
ViewUsedVehicle.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(ViewUsedVehicle);