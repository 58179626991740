import CommentHistory from "./B2Bpayments/CommentHistory";
import PaymentProcess from "./B2Bpayments/PaymentProcess";
import Status from "./B2Bpayments/Status";
import PostAgentProfile from "./B2Bpayments/PostAgentProfile";
import RecievedAgent from "./B2Bpayments/RecievedAgent";
// import moment from "moment";
import moment from 'moment-timezone';
import TotalAmount from "./B2Bpayments/TotalAmount";
import Commission from "./B2Bpayments/Commission";
import PostAgentProfileB2C from "./B2Cpayments/PostAgentProfileB2C";
import RecievedAgentB2C from "./B2Cpayments/RecievedAgentB2C";
import TotalAmountB2C from "./B2Cpayments/TotalAmountB2C";
import CommissionB2C from "./B2Cpayments/CommissionB2C";
import PaymentProcessB2C from "./B2Cpayments/PaymentProcessB2C";
import CommentHistoryB2C from "./B2Cpayments/CommentHistoryB2C";
import StatusB2c from "./B2Cpayments/StatusB2c";
import UserID from "./AllBookings/UserID";
import PostedAgent from "./AllBookings/PostedAgent";
import BookingStatus from "./AllBookings/BookingStatus";
import BookingDriver from "./AllBookings/BookingDriver";
import SubscriberProfile from "./User-Subscriptions/SubscriberProfile";
import Action from "./VehicleType/Action";
import UserVerify from "./MobileUsers/UserVerify";
import UserActions from "./MobileUsers/UserActions";
import UserProfile from "./MobileUsers/UserProfile";
import TableActions from "../../views/Used Cars/Table_actions/actionController";





export const convertTextField=(data)=>{
   
    const text=data
    if (!data) return "-";
        return data.charAt(0).toUpperCase() + text.slice(1);
}



export const AggregateRowFields = (section,rowData) => {

   
    let newData = rowData.map((ele) => {
   

    switch(section){
        case "B2BRows":
           
            return {
                'ID': ele.bookingId || '',
                'Date': moment(new Date(ele.paymentReceivedDate)).format("MMMM D, Y") || '-',
                'Posted By': ele.postedFullName || "-",
                'Received By': ele.receivedFullName || '-',
                'Status': ele.paymentStatus,
                'Total Amount':ele.bookingCommission || '-',
                'Our Commission': ele.taxiSanchalakCommission || '-',
                'Hold or Release Payment': ele.paymentStatus ,
                'actualData':ele
            }
        case "B2B_Failed_Payments" :
            return {
                'Booking ID' : ele.bookingId ? ele.bookingId : 'N/A',
                'Agent ID' : ele.agentId ? ele.agentId : 'N/A',
                'Agent Mobile Number' : ele.agentMobileNumber ? `+91 ${ele.agentMobileNumber}` : "N/A" ,
                'Transaction ID' : ele.transactionId ? ele.transactionId : 'N/A' ,
                'assignedAgentId' : ele.assignedAgentId ? ele.assignedAgentId : 'N/A' ,
                'Assigned Agent Mobile Number' : ele.assignedAgentNumber ? `+91 ${ele.assignedAgentNumber}` : "N/A" ,
                'Assigned Agent Transaction ID' : ele.assignedAgentTransactionId ? ele.assignedAgentTransactionId : 'N/A' ,
                'Status' :  ele.paymentStatus ? ele.paymentStatus : 'N/A',
                'Payment Mode' : ele.paymentMode ? ele.paymentMode : 'N/A',
                'Amount (₹)' : ele.amount ? `₹${ele.amount}` : "-",
                'Created On' : ele.created_at ? ele.created_at : 'N/A',
                'Last Updated On' : ele.updated_at ? ele.updated_at : 'N/A'
            }

        case "B2CRows":
            return {
                'ID':ele.bookingId || '',
                'Date':moment(new Date(ele.paymentReceivedDate)).format("MMMM D, Y") || '-',
                'Posted By': ele.postedFullName ||'',
                'Recieved By': ele.receivedFullName ||'',
                'Status': ele.paymentStatus,
                'Total Amount':ele.bookingCommission || '-',
                'Our Commission': ele.taxiSanchalakCommission || '-',
                'Hold or Release Payment': ele.paymentStatus ,
                'actualData':ele
            }
        
        case "BookingRows":
             
            return {
    
                "ID":ele.bookingId || "-",
                "Pickup": ele.pickupCity || 'NA',
                "Drop": ele.dropCity || 'NA',
                "Vehicle Type": ele.vehicleTypeName || '',
                "Pickup Date": moment(new Date(ele.pickupDate)).format("MMMM D, Y") || '-',
                "Trip": ele.bookingType?.charAt(0).toUpperCase() + ele.bookingType?.slice(1) || '-',
                "Amount":`₹ ${ele.bookingAmount}`|| 'NA',
                "Status": ele.bookingStatus || '-',
                "actualData":ele
                   }
            
        case "AllBookingRows":  
                  return {
          
                      "ID":ele.bookingId || "-",
                      // "Agent":'',
                      "Pickup": ele.pickupCity || 'NA',
                      "Drop": ele.dropCity || 'NA',
                      "Vehicle Type": ele.vehicleTypeName || '',
                      "Pickup Date": moment(new Date(ele.pickupDate)).format("MMMM D, Y") || '-',
                      "Trip": ele.bookingType?.charAt(0).toUpperCase() + ele.bookingType?.slice(1) || '-',
                      "Amount":`₹ ${ele.bookingAmount}`|| 'NA',
                      "Status": ele.bookingStatus || '-',
                      // "Driver":'',
                      "actualData":ele
                         }

        case "PremiumUsers" : 
            return {
                "User" : ele.fullName || "",
                "Mobile Number" : `+91 ${ele.mobileNumber}` || "",
                "Expiry Date" : ele.secureDate || "NA",
                "Subscription Type" : ele.planTitle || "NA",
                "actualData" : ele
            }

        
        case "FreeUsers" :
            return {
                "User" : convertTextField(ele.fullName) || "",
                "Mobile Number" : `+91 ${ele.mobileNumber}` || "",
                "actualData" : ele
            }


        case "autoSubscriptionUsers" :
            return {
                "ID":ele.id||"NA",
                "User" : convertTextField(ele.fullname) || "",
                "Mobile Number" : `+91 ${ele.mobileNumber}` || "",
                "Expiry Date" : moment(new Date(ele.secureDate)).format('MMMM D, Y') || "NA",
                "Subscription Type" : ele.planTitle || "NA",
                "Status": ele.status ||'NA',
                "actualData" : ele
            }

        case "vehicleType":
            return {
                "ID": ele.vehicleTypeId,
                "Type":ele.vehicleTypeName||"",
                "Seat":ele.noOfSeats || 1,
                "action":"",
                "actualData":ele
            }
        
        case "ExportDataRows":
            return {
                "User":ele.fullName || "-",
                "Vehicle Number":ele.registrationNumber || "-",
                "Vehicle Type":ele.vehicleTypeName || "-",
                "Year Of Manufacture":ele.yearOfManufacture || "-",
                "Mobile Number": `+91 ${ele.mobileNumber}` || "-",
                "Position": ele.userType || "-",
                "Join Date": ele.createTs ? moment(new Date(ele.createTs)).format('MMMM D, Y') : '-',
                "actualData":ele
            }
        
        case "MobileUSers":
            return {
                "User":ele.fullName||"-",
                "Email":ele.email || "-",
                "Mobile Number": `+91 ${ele.mobileNumber}` || "-",
                "Company": ele.companyName || "-",
                "User Type": convertTextField(ele.userType) || "-",
                "Join Date": moment(new Date(ele.createTs)).format('MMMM D, Y') || "-",
                "Status": ele.verifiedFlag || "-",
                "Actions":ele.enabledStatus,
                "actualData":ele
            }
        
        case "UsedCarSales":
            return {
                "Location":ele.location || '-',
                "Vehicle No":ele.vehicleNumber || '-',
                "Vehicle Name":ele.vehicleName || '-',
                "Vehicle Price":ele.carPrice ||'-',
                "Total Kms driven":ele.totalKmDriven||'-',
                'Transmission':ele.transmission||'-',
                "actualData":ele
            }
        case 'B2B_success_Payments':
            return {
                "Booking ID":ele.bookingId||"-",
                "Payment Date":moment(new Date(ele.paymentDate)).format('MMMM D, Y')||'-',
                "Agent Name":ele.agentName||'-',
                "Agent Mobile Number":`+91${ele.agentMobileNumber}`||"-",
                "Driver Name":ele.driverName||"-",
                "Driver Mobile Number":`+91${ele.driverMobileNumber}`||"-",
                "Transaction ID":ele.transactionId||'-',
                "Booking Amount":`₹${ele.bookingAmount}`||"-",
                "Booking Commission":ele.bookingCommission?`₹${ele.bookingCommission}`:"N/A",
                "Status":ele.paymentStatus||"-",
            }

            default :
                return {}
    }
    });
    
    return newData;
}







// =================================  ColumnFields =====================




export const B2BFields = [
    { field: "ID" , sortable:true, sort:"desc"},
    
    { 
        field: "Date",
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;

                const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            browserDatePicker: true
        },
        cellRenderer: (params) => moment(params.value, "MMMM D, Y").format("MMMM D, Y")
    },
    { 
        field: "Posted By", 
        cellRenderer: PostAgentProfile, 
        floatingFilter: false
    },
    { field: "Received By",cellRenderer:RecievedAgent },
    { 
        field: "Status",
        cellRenderer: Status 
    },
    { field: "Total Amount",
    cellRenderer:TotalAmount

},
    { field: "Our Commission" ,cellRenderer:Commission},
    { field: "Hold or Release Payment" ,
    filter:true,
      floatingFilter:false,
    cellRenderer:PaymentProcess },
    { field: "Comment History",
    filter:false,
    cellRenderer:CommentHistory }
];

export const B2B_Failed_Payments_Fields=[
    { field: "Booking ID" ,},
    { field: "Agent ID" ,},
    { field: "Agent Mobile Number" ,},
    { field: "Transaction ID" ,},
    { field: "Assigned Agent ID" , cellRenderer : value=> value.data.assignedAgentId },
    { field: "Assigned Agent Mobile Number" ,},
    { field: "Assigned Agent Transaction ID" , },
    { field: "Payment Status" , cellRenderer: Status },
    { field: "Payment Mode" ,},
    { field: "Amount (₹)" , },
    { field: "Created On" , cellRenderer : (params)=>{
        let createdTime= moment(params.value).tz('Asia/Kolkata').utcOffset('+05:30').format("h:mm:ss a");
        return createdTime
     }
    },
    { field: "Last Updated On " , 
        cellRenderer : (params)=>{
            let updatedTime= moment(params.value).tz('Asia/Kolkata').utcOffset('+05:30').format("h:mm:ss a");
            return updatedTime
         },
     }
]


export const B2CFields = [
    { 
        field: "Date",
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;

                const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            browserDatePicker: true
        },
        cellRenderer: (params) => moment(params.value, "MMMM D, Y").format("MMMM D, Y")
    },
    { 
        field: "Posted By", 
        cellRenderer: PostAgentProfileB2C, 
        floatingFilter: false
    },
    { 
        field: "Received By",
        filter: "agTextColumnFilter",
        valueGetter: (params) => params.data.actualData.receivedFullName, // Use valueGetter to specify the field for filtering
        cellRenderer: (params) => {
            return RecievedAgentB2C(params);
        }
    },    
    { 
        field: "Status",
        cellRenderer: StatusB2c 
    },
    { field: "Total Amount",
    cellRenderer:TotalAmountB2C

},
    { field: "Our Commission" ,cellRenderer:CommissionB2C},
    { field: "Hold or Release Payment" ,
    filter:true,
      floatingFilter:false,
    cellRenderer:PaymentProcessB2C },
    { field: "Comment History",
    filter:false,
    cellRenderer:CommentHistoryB2C }
];




export const BookingsFields = [
    { field: "ID" , sortable:true, sort:"desc", filter:true ,floatingFilter:false ,cellRenderer:UserID},
    
    {
        field:"Agent",
        filter:true,
        floatingFilter:false , 
        cellRenderer:PostedAgent ,
        valueGetter:(params)=>params.data.actualData.postedAgentName
    },
    { 
        field: "Pickup", floatingFilter: false
    },
    { field: "Drop",},
   
    { field: "Vehicle Type",},
    { field: "Pickup Date" ,
    filter: "agDateColumnFilter",
    filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) return -1;

            const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            } else if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else {
                return 1;
            }
        },
        browserDatePicker: true,
    }

    },
    { field: "Trip" ,filter:true,floatingFilter:false},
    { field: "Amount",filter:true,floatingFilter:false},
    {field:"Status", filter:true,floatingFilter:false ,cellRenderer:BookingStatus},
    {field: "Driver" , 
        filter:true,
        floatingFilter:false,
        cellRenderer:BookingDriver,
        valueGetter:(params)=>{
            return params.data.actualData.driverAgentName?params.data.actualData.driverAgentName :"NA" 
        }
        }
];







export const FreeUsers=[
    {field :"User" , cellRenderer : SubscriberProfile , flex:1},
    {field :"Mobile Number" , flex:1}
]




export const PremiumUsers=[
    {field:"User" , cellRenderer : SubscriberProfile , flex:1},
    {field:"Mobile Number" ,flex :1},
    {field:"Expiry Date" ,
        // date filter for string exact date .
        filter:"agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                if (!cellValue) return -1;
            
                // Parse the cell date using moment
                const cellDate = moment(cellValue, "DD-MM-YYYY").startOf('day').toDate();
            
                // Compare dates
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            debounceMs: 500 ,
            browserDatePicker: true
        },
       
        cellRenderer:(params) => {
            return moment(params.value, "DD-MM-YYYY").format("MMMM D, YYYY");
        },

        flex :1},
    {field:"Subscription Type" ,flex :1}
]



export const autoSubscriptionUsers=[
    {field:"ID" ,flex:1}, 
    {field:"User" ,flex:1 } ,
    {field:"Mobile Number" ,flex:1} ,
    {field:"Expiry Date" ,flex:1 ,
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;

                const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            browserDatePicker: true
        },
    } ,
    {field:"Subscription Type" ,flex:1} ,
    
]




export const VehicleTypeFields=[
    { headerName: "ID", field: "ID", flex: 1 },
    { headerName: "Type", field: "Type", flex: 1 },
    { headerName: "Seat", field: "Seat", flex: 1 },
    { headerName: "Action", field: "Action", flex: 1 ,cellRenderer:Action },
]




export const ExportDataFields=[
    {field:"User",},
    {field:"Vehicle Number"},
    {field:"Vehicle Type"},
    {field:"Year Of Manufacture"},
    {field:"Mobile Number"},
    {field:"Position"},
    {field:"Join Date" ,
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;

                const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            browserDatePicker: true
        }
    }
]



export const mobileUsers=[
    {field:"User" , cellRenderer : UserProfile},
    {field:"Email"},
    {field:"Mobile Number"},
    {field:"Company"},
    {field:"User Type"},
    {field:"Join Date" , 
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;

                const cellDate = moment(dateAsString, "MMMM D, Y").toDate();

                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else {
                    return 1;
                }
            },
            browserDatePicker: true
        }
    },
    {field:"Status" , cellRenderer : UserVerify},
    {field:"Actions" , cellRenderer : UserActions},
]


export const UsedCarSalesFields=[
    {field:"Location" ,flex:1},
    {field:"Vehicle No" ,flex:1},
    {field:"Vehicle Name" ,flex:1},
    {field:"Vehicle Price",flex:1},
    {field:"Total Kms driven",flex:1},
    {field:"Transmission",flex:1},
    {field:"Actions" , flex:1 ,cellRenderer: TableActions}
]

export const B2B_success_Payments_Fields=[
    {
        field: "Booking ID",
        cellRenderer: (params) => params.value
      },
    {field:'Payment Date',},
    {field:'Agent Name',},
    {field:'Agent Mobile Number',},
    {field:'Driver Name',},
    {field:'Driver Mobile Number',},
    {field:'Transaction ID'},
    {field:'Booking Amount'},
    {field:'Booking Commission',},
    {field:'Status' , cellRenderer:Status},
]