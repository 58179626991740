import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomDropdown from '../../components/Dropdown/CustomDropdown';
import { CiSearch } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Link, Outlet, useNavigate } from 'react-router-dom';

const UsedCars = ({token}) => {

  const navigate=useNavigate();

  useEffect(()=>{navigate('/app/used-cars/sales')},[])
    return (<Outlet/>);
}
UsedCars.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(UsedCars);
