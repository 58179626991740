import React from 'react';
import { CiSearch } from 'react-icons/ci';
import CustomDropdown from '../../../components/Dropdown/CustomDropdown';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import styles from '../usedCars.module.scss'
import { connect, useSelector } from 'react-redux';

const TableController = () => {
    const store=useSelector(state=>state);
    const value='select city'
    const data=['Select City','Bangalore','Kerala','Kolkata',]
    const value2='Vehicle Type'
    const data2=['Select Vehicle Type','Automatic','Manual',]

    const tableController = (e) => {
        let gridRef = store.table.gridRef; // Accessing the grid reference from the store
        if (gridRef.current && gridRef.current.api) {
            if (e === 'Select city'||e=== 'Select Vehicle Type' ) {
                // Reset the filter if 'select city' is passed
                gridRef.current.api.setQuickFilter('');
            } else {
                // Apply the filter for other cases
                gridRef.current.api.setQuickFilter(e);
            }
        }
    };
    
    return (
        <>
            <div className={styles.panelControl}>
                <div className={styles.searchContainer}>
                <CiSearch size={30}/>
                <input  type='search' placeholder='Search' onChange={(e)=>tableController(e.target.value)} />
                </div>
            <div className={styles.panelSub}>
            <CustomDropdown value={value} data={data} tableController={tableController} />
            <CustomDropdown value={value2} data={data2} tableController={tableController} />
            <Link to='/app/used-cars/add' className={styles.addVehicleButton} >
            <IoIosAddCircleOutline size={20}/>Add Vehicle
            </Link>
            </div>
            </div>
            
        </>
    );
}

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(TableController);
