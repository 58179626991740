import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { connect } from 'react-redux';
import styles from './usedCars.module.scss'
import { Accordion, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { validateFields } from './Single car actions/Validate';
import { toast } from 'react-toastify';
import { useImageCompression, useImageCompressor } from '../../components/ImageCompression/useImageCompression';
import { API_ADD_USED_CARS } from '../../utils/consts/api';

  const AddUsedVehicle = ({token}) => {
    const {compressImage} = useImageCompressor();

    const carData = {
      carImages: [], // Should be populated with File objects from an input field
      vehicleName: "",
      vehicleModelNumber: "",
      fuelType: "",
      transmission: "",
      carPrice: "",
      loanRemaining: "",
      isValueSatisfied: "",
      isInspectionDone: "",
      statePermit: "",
      insuranceType: "",
      insuranceValue: "",
      fitness: "",
      tyreCondition: "",
      allIndiaPermit: "",
      authorization: "",
      insuranceExpiry: "",
      totalKmDriven: "",
      stateTax: "",
      reportDescription: "",
      location: "",
      vehicleNumber: "",
      ownershipYear: "",
  };
  
  const [newCarData, setNewCarData] = useState(carData);
    const addUsedCar = async (carData, authToken) => {
      let result = validateFields(carData);
      if (typeof result !== 'boolean') {
          toast.error(result, {
              position: "top-right",
              autoClose: 2300,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          return;
      }
  
      try {
          const formData = new FormData();
          if (carData.carImages && carData.carImages.length > 0) {
              carData.carImages.forEach((file, index) => {
                  formData.append("carImages", file);
              });
          }
          Object.entries(carData).forEach(([key, value]) => {

              if (key !== "carImages") { 
                  formData.append(key, value.toString());
              }
          });
          for (let pair of formData.entries()) {
  
          }
  
          const response = await axios.post(API_ADD_USED_CARS, formData, {
              headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: token,
              }
          });
  
          toast.success("Vehicle added successfully!");
          return response.data;
      } catch (error) {
          toast.error(error.response?.data?.message || "Error adding vehicle");
          throw error;
      }
  };
    return (
        <>
        <div className={styles.panelControl}>
          <h1 >Add a vehicle</h1>
            <div className={styles.panelSub}>
            <Link to='/app/used-cars/sales' className={styles.cancelButton}>Cancel</Link>
            <button className={styles.addVehicleButton} onClick={()=> addUsedCar(newCarData)}>
            <IoIosAddCircleOutline size={20}/>Add Vehicle
            </button>
            </div>
        </div>

        <div className={styles.contentWrapper}>
    <Accordion defaultActiveKey='0'>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.accordianHeader} style={{height:'54px'}}>Car Details</Accordion.Header >
        <Accordion.Body>
        <form className={styles.formControl}>
        <div className={styles.formGroup}>
            <label htmlFor="carImage">Upload Car Images</label>
            <input type="file" className="form-control" id="carImage" 
            accept='image/*' 
            multiple
            // onChange={(
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, carImages: Array.from(e.target.files) }))}
            />
        </div>

        <div className={styles.formGroup}>
            <label htmlFor="vehicleName">Vehicle Name</label>
            <input type="text" className="form-control" id="vehicleName" placeholder="Enter vehicle name" value={newCarData.vehicleName} onChange={(e)=>setNewCarData((prev) => ({ ...prev, vehicleName: e.target.value }))}/>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="fuelType">Fuel Type</label>
          <Form.Select 
              id="fuelType"  
              aria-label="Select Fuel Type" 
              value={newCarData.fuelType} 
              onChange={(e) => setNewCarData((prev) => ({ ...prev, fuelType: e.target.value }))}>
              <option value="">Select</option>
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
              <option value="Electric">Electric</option>
          </Form.Select>
        </div>


        <div className={styles.formGroup}>
            <label htmlFor="transmission">Transmission</label>
            <Form.Select id="transmission" aria-label="Select Transmission"
            value={newCarData.transmission}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, transmission: e.target.value }))}>
            <option value=''>Select Transmission</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
            </Form.Select>
        </div>

        <div className={styles.formGroup}>
            <label htmlFor="vehicleName">Location</label>
            <input type="text" className="form-control" id="vehicleLocation" placeholder="Enter location" value={newCarData.location} onChange={(e)=>setNewCarData((prev) => ({ ...prev, location: e.target.value }))}/>
        </div>

        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
    <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Price for listing</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
        <div className={styles.formGroup}>
            <label htmlFor="price">Car Price</label>
            <input type="number" className="form-control" min='0' id="price" placeholder="Enter price"
            value={`${newCarData.carPrice}`}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, carPrice: e.target.value }))} />
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="loan">Loan Remaining</label>
            <input type="number" className="form-control" min='0' id="loan" placeholder="Enter loan" 
            value={newCarData.loanRemaining}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, loanRemaining: e.target.value }))} />
        </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Certifications</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
          <div className={styles.formCheckBox}> 
            <div className={'formGroup'} style={{display:'flex'}}>
            <input type="checkbox" id="satisfied" name="certifications" value="satisfied" 
            checked={newCarData.isValueSatisfied ==='1'?true:false}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, isValueSatisfied: e.target.checked?'1':'0' }))}/>
            <label htmlFor="satisfied" >Value satisfied</label>
            </div>
            <div className={''} style={{display:'flex'}}>
            <input type="checkbox" id="inspection" name="certifications" value="inspection" 
            checked={newCarData.isInspectionDone ==='1'?true:false}
            onChange={(e)=>{
              setNewCarData((prev) => ({ ...prev, isInspectionDone: e.target.checked?'1':'0' }))
            }}/>
            <label htmlFor="inspection">Inspection done</label>
            </div>
          </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Car overview details</Accordion.Header>
        <Accordion.Body>
        <form className={styles.formControl}>
            <div className={styles.formGroup}>
            <label htmlFor="ownership">Ownership</label>
            <input type="text" id="ownership" className="form-control" placeholder="Enter Year" 
            value={newCarData.ownershipYear} 
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, ownershipYear: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="vehicleNumber">Vehicle Number</label>
            <input type="text" id="ownership" className="form-control" placeholder="vehicleNumber" 
            value={newCarData.vehicleNumber} 
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, vehicleNumber: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="ownership">vehicle Model Number</label>
            <input type="number" id="model" className="form-control" placeholder="Enter Year" 
            value={newCarData.vehicleModelNumber}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, vehicleModelNumber: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="statePermit">State Permit</label>
            <input type="text" id="statePermit" className="form-control" placeholder="Enter permit details" 
            value={newCarData.statePermit}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, statePermit: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="statePermit">All India Permit</label>
            <Form.Select id="transmission" aria-label="Select permit"
            value={newCarData.allIndiaPermit}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, allIndiaPermit: e.target.value }))}>
            <option>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            </Form.Select>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceType">Insurance Type</label>
            <input type="text" id="insuranceType" className="form-control" placeholder="Enter type" 
            value={newCarData.insuranceType}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, insuranceType: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceValue">Insurance Value</label>
            <input type="text" id="insuranceValue" className="form-control" placeholder="Enter value" min='0' 
            value={newCarData.insuranceValue} 
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, insuranceValue: e.target.value }))}/>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="fitnessExpiry">Fitness Expiry</label>
            <input type="text" id="fitnessExpiry" className="form-control" placeholder="Enter expiry date" 
            value={newCarData.fitness} 
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, fitness: e.target.value }))}/>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="tyreCondition">Tyre Condition</label>
            <input type="text" id="tyreCondition" className="form-control" placeholder="Enter condition" 
            value={newCarData.tyreCondition} 
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, tyreCondition: e.target.value }))}/>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="authorization">Authorization</label>
            <input type="text" id="authorization" className="form-control" placeholder="Enter type of authorization" 
            value={newCarData.authorization}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, authorization: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="insuranceExpiry">Insurance Expiry</label>
            <input type="date" id="insuranceExpiry" className="form-control" 
            value={newCarData.insuranceExpiry} 
            onChange={(e)=>{setNewCarData({...newCarData,insuranceExpiry:e.target.value})}}/>
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="totalKms">Total KMs Driven</label>
            <input type="text" id="totalKms" className="form-control" placeholder="Enter total kms" min='0'  
            value={newCarData.totalKmDriven}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, totalKmDriven: e.target.value }))} />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="stateTax">State Tax</label>
            <input type="text" id="stateTax" className="form-control" placeholder="Enter state tax" 
            value={newCarData.stateTax}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, stateTax: e.target.value }))} />
            </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordianHeader}>Report</Accordion.Header>
        <Accordion.Body className={styles.accordianBody}>
        <form className={styles.formControl}>
            <div className={styles.formGroup}>
            <label htmlFor="satisfied">Write report</label>
            <textarea type='text' placeholder='Write a description' 
            value={newCarData.reportDescription}
            onChange={(e)=>setNewCarData((prev) => ({ ...prev, reportDescription: e.target.value }))} />
            </div>
        </form>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br/>
      <div className={styles.panelSub}>
            <button className={styles.cancelButton}>Cancel</button>
            <button className={styles.addVehicleButton} >
            <IoIosAddCircleOutline size={20}/>Add Vehicle
            </button>
            </div>
        </div>
        </>
    );
}
AddUsedVehicle.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(AddUsedVehicle);